/* body, h1, h2, h3, h4, h5, h6, div, P, btn, div  {
    font-family: "Segoe UI", Arial, sans-serif;
} */

body, h1, h2, h3, h4, h5, h6, div, p, btn, div, button, label, input, select, textarea, a, span, table, thead, tr, th, td, tbody, tfoot, span, select, option, abbr{
    /* font-family: 'Noto Sans', sans-serif; */
    /* font-family: 'Didact Gothic', sans-serif; */
    font-family: 'Quicksand', sans-serif !important;
}

P {
    font-size: 0.9em;
}

h6 {
    font-size: 1.1em;
}

hr {
    border-top: 1px solid #004545;
}

button {
    border-radius: 2px !important;
    margin-top: 1vh;
    /* width: 12vh; */
    margin: 1vh;
}

input::-webkit-input-placeholder {
	font-size: 0.85em !important;
}

.footer {
    /* position: absolute;
    bottom: 0;
    left: 0;
    right: 0; */
    /* height: 5vh; */
    background-color: #fff;
    margin-top: 2vh;
    font-family: Segoe UI,Frutiger,Frutiger Linotype,Dejavu Sans,Helvetica Neue,Arial,sans-serif; 
}

.footer-text {
    display: block;
    text-align: center;
    color: #016e57;
    /*  */
    font-size: 0.9em;
    /* margin-bottom: auto; */
    font-weight: bold;
}

.header {
    width: 100%;
}

.black {
    height: 4px;
    background-color: black;
    width: 100%;
}
.yellow {
    height: 4px;
    background-color: yellow;
    width: 100%;
}
.red {
    height: 4px;
    background-color: red;
    width: 100%;
}

.headerimage {
    height: 60px;
    margin-top: 15px;
    margin-left: 10px;
}

.coatimage {
    text-align: right;
    display: block;
    margin-right: 20px;
}

.headertext {
    text-align: center;
    padding: 5px;
}

.mainheadertext {
    font-weight: bold;
    font-size: 0.9em;
    padding: 0px;
    margin: 0px;
}

.headercol1 {
    display: block;
    margin-left: 20px;
}

.systemtitle {
    /* font-weight: bold; */
    font-size: 0.9em;
    padding: 0px;
    display: inline-block;
    text-decoration-line: overline;
}

.model-content-design {
    border-radius: 2px !important;
    border: 0px solid #fff !important;
    background-color: #F6FCFC !important;
    -webkit-box-shadow: 4px 4px 8px 0px #5E5E5E; 
    box-shadow: 4px 4px 8px 0px #5E5E5E;
}

.model-header-content {
    /* background-color: #016e57; */
    color: #004545;
    border-radius: 2px !important;
    margin-bottom: 1vh;
    padding-top: 1.5vh;
    padding-bottom: 1.5vh;
    border-bottom: 0px !important;
    
    
}

.model-title-style {
    font-size: 1em !important;
    font-weight: bold !important;
    text-align: left !important;
}

.modal-header {
    display: flex;
}

.model-title {
    color: #016e57;
    font-weight: 500;
}

.model-footer-content {
    border-top: #fff !important;
}

.model-close {
    color: #fff;
}

.item-center {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: center;
}

.submit-btn {

}

.confirmationmodel {
    background-color: #fff;
}

.imgcontainer{
    position: relative;
}

.passportimg {
    /* width: 400px; */
    max-width: 100%;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 12%;
    transform: translateY(-50%);
}

.btn-grad {
    /* background-image: linear-gradient(to bottom, #0c4579 0%,  #296bb1  100%); */
    background-image: linear-gradient(to bottom, #2b79c3 0%,  #3287d7  100%);
    /* margin: 10px; */
    /* padding: 15px 45px; */
    border-width: 0px;
    text-align: center;
    /* text-transform: uppercase; */
    transition: 0.5s;
    background-size: 200% auto;
    color: #fff;
    font-weight: 500;          
    /* box-shadow: 0 0 20px #eee; */
    border-radius: 2px;
    /* display: block; */
  }

.btn-grad:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
}

         
.btn-grad-secondary {
    /* background-image: linear-gradient(to bottom, #004241 0%, #0f7863  100%); */
    background-image: linear-gradient(to bottom, #196867 0%, #228987 100%);
    /* margin: 10px; */
    border-width: 0px;
    /* padding: 15px 45px; */
    text-align: center;
    /* text-transform: uppercase; */
    transition: 0.5s;
    background-size: 200% auto;
    color: #fff;
    font-weight: 500;             
    /* box-shadow: 0 0 20px #eee; */
    border-radius: 2px;
    /* display: block; */
  }

  .btn-grad-primary {
    background-image: linear-gradient(to bottom, #9c9e9e 0%, #808282 100%);
    border-width: 0px;
    text-align: center;
    transition: 0.5s;
    background-size: 200% auto;
    color: #fff;
    font-weight: 500;             
    border-radius: 2px;
  }

.btn-grad-secondary:hover {
    background-position: right center; /* change the direction of the change here */
    color: #ffff;
    text-decoration: none;
}

.error-message {
    font-size: 0.65em;
    font-style: italic;
    color: red;
}

.phone-input {
    border-radius: 3px !important;
    /* border-color: red !important; */
    width: 100% !important;
    border-radius: 3px !important;
}

.resume-btn {
    width: 35%;
    padding: 1.5vh;
    /* float: right; */

}

/* .resume-container {
    display: flex;
} */

.btn-padding {
    padding: 1vh;
}

.btn-right {
    align-self: flex-end;
}

.react-calendar__tile--now{
    background: #004241 !important;
    color: white !important;
}


@media (max-width: 768px) {
    .headerimage {
        display: none;
    }

    .passportimg {
        display: none;
    }
    .headertext {
        text-align: center;
        padding: 5px;
    }

    .footer-text {
        font-size: 0.7em;
    }

}

.buttontextsize {
    font-size: 0.9em !important;
}
