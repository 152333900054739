.home-container {
    display: block;
    min-height: 78vh;
    width: 100%;
    background-color: #196867;
    
}

.innercontainer {
    background-color:  #C5D9D9;
    min-height: 75vh;
    margin: 1vh;
    border-radius: 3px;
    -webkit-box-shadow: 5px 5px 10px 1px rgba(0,0,0,0.6); 
    box-shadow: 5px 5px 10px 1px rgba(0,0,0,0.6);
}

.homepadding{
    padding-left: 0;
    padding-right: 0;
}
.homecontainer {
    margin: 1vh;
    opacity: 1;
    /* background-color: #004241; */
    padding-bottom: 5vh;
    padding-left: 3vh;
    padding-right: 3vh;
    margin-top: 3vh;
    margin-bottom: 3vh;
    /* -webkit-box-shadow: 5px 2px 13px 0px rgba(0,0,0,0.5); 
    box-shadow: 5px 2px 13px 0px rgba(0,0,0,0.5); */

   

}

.buttongroup {
    display:flex;
    flex-direction: column;
    /* justify-content: center;
    align-items: center;
    background-color: transparent; */
    justify-content: center;
    align-items: center;
    background-color: transparent;

}

.btn-content {
    display:flex;
    flex-direction: column;
    justify-content: content;
    align-items: center;
    padding: 0;
}



.innertext {
    color: #fff;
    text-align: center;
    font-size: 1.5em !important;
    padding: 1em;
    font-weight: 500;
    display: block;
}

.innertext-two {
    color: #000;
    text-align: center;
    font-size: 1.2em !important;
    padding: 1em;
    font-weight: 500;
    display: block;
}

.buttonstyle {
    border-radius: 3px;
    width:90%;
    margin: 0.1em 1em 0.6em 1em;
    text-align: center;
    height: 10vh;
}

.otherbuttonstyle {
    border-radius: 3px;
    width:90%;
    margin: 0.1em 1em 0.6em 1em;
    text-align: center;
    height: 10vh;
    /* background-color: #004545 !important; */
    /* background: #ffffffc6; */
    /* font-weight: 500; */
    /* color: black; */
    border-width: 0px !important;
}

/* .otherbuttonstyle:hover{
    background: #ffffffb1;
    color: #222222 !important;

} */



.backgroundimage {
    background-image: url("../assets/img/passports4.png");
    background-repeat: no-repeat;
    height: 75vh;
    /* background-attachment: fixed; */
    background-position: center center;
    /* padding-left: 10em; */
    background-size: 90%;
}

@media (max-width: 768px) {
    .backgroundimage {
        display: none;
    }
}