/* .home-container {
    display: block;
    min-height: 80vh;
    width: 100%;
    background-color: #196867;
} */

.innercontainer {
    /* background-color: #016161; */
    min-height: 70vh;
    margin: 1vh;
    /* background-color: #fff; */
    /* opacity: 0.2; */
}

.buttoncontainer {
    /* background-color: #016161; */
    padding: 1em;
    margin: 1em;
}

.buttongroup {
    display:flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    vertical-align: center;
}


.innertext {
    color: #fff;
    text-align: center;
    font-size: 1em;
    padding: 1em;
    display: block;
}

.buttontext {
    color: #fff;
    display: inline-block
}

.textbold {
    font-weight: bold;
}

.buttonstyle {
    border-radius: 2px;
    min-width:80%;
    margin: 0.1em 1em 0.6em 1em;
    text-align: center;
    height: 10vh;
}

.howtogroup {
    display: block !important;
    text-align: justify;
    width: 50vw;
}

.howtotext {
    font-size: 1em;
}

.howtobold {
    font-weight: bold;
}



