.status-container {
    display: block;
    min-height: 65vh;
    min-width: 100%;
    background-color: #196867;
    padding-top: 2vh;
    /* padding-left: 20vh;
    padding-right: 20vh; */
    padding-bottom: 2vh;
    
}

.header-text {
    font-size: 25px;
    font-weight: 900;
}

.body-text {
    font-size: 15px;
    font-weight: 700;
}

.text-maroon {
    color: red;
}

.text-green {
    color: #196867;
}

.statusform {
    min-height: 60vh;
    width: 80%;
    background-color: #fff;
    -webkit-box-shadow: 5px 2px 13px 0px rgba(0,0,0,0.5); 
    box-shadow: 5px 2px 13px 0px rgba(0,0,0,0.5);
}

.svgcontainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.svg-icon-container {
    min-height: 20vh !important;
}

.linesvg {
    width: 100%;
}

.statussvg {
    height: 9vh;
    padding: 0;
}

.statusthree {
    width: 7vw;
}

.text-grey {
    color: grey;
}

.svg-icon {
    height: 30px;
}

.svg-line {
    width: 2vw;
}

.status-width {
    width: 90%;
}

@media only screen and (max-width: 600px) {
    .status-width {
        width: 100%;
    }
}