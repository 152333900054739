.application-container {
    display: block;
    min-height: 65vh;
    min-width: 100%;
    background-color: #196867;
    padding-top: 2vh;
    padding-left: 20vh;
    padding-right: 20vh;
    padding-bottom: 2vh;
    
}

.applicationform {
    /* min-height: 70vh; */
    width: 80%;
    background-color: #fff;
    -webkit-box-shadow: 5px 2px 13px 0px rgba(0,0,0,0.5); 
    box-shadow: 5px 2px 13px 0px rgba(0,0,0,0.5);
}


.control-btn {
    border-radius: 2px;
    margin-top: 1vh;
    display: block;
    width: 12vh;
    margin: 1vh;
    
}

control-btn {
    
}

.adddependantcont {
    padding-top: 2rem;
}

.addbtn {
    border-radius: 2px;
    margin-top: 5rem !important;
    width: 12vh;
    height: 4vh;
    font-size: 0.95em !important;
    font-weight: 550;
    background: #ced4da72;
    color: rgb(105, 105, 105);
    /* color: #004545; */
    border: 1px solid #ced4da;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 1rem;
}

.dependantplus {
    margin-right: 0.5rem;
}

.addbtn:hover {
    background: #ced4da;
    color: #000;
    border: 1px solid #ced4da;
}

/* .addbtn:active{
    border-radius: 0px;
    margin-top: 1vh;
    width: 12vh;
    height: 4vh;
    font-size: 1em;
    background: #fff;
    color: #004545;
    border: 1px solid #ced4da;
} */

.prev-btn {
    
    align-items: left;
}

.next-btn {
    /* margin-left: auto; */
    margin-right: 0;
}

.save-btn {
    min-width: 120px;
}

.step-container {
    /* border-right: 1px solid #004545; */
    min-height: 70vh;
    margin-left: 0px;
    
}

.steps {
    display: flex;
    min-width: 100%;
    min-height: 6.3vh;
    align-items: center;
    /* padding-top: 2vh; */
    box-shadow: 0 0 1px;
    padding-left: 1vh;
    /* font-size: 0.8em; */
    font-size: 0.8vw;
    color: rgb(105, 105, 105);
    font-weight: 400;
    background: rgb(242,242,242);
    background: linear-gradient(0deg, rgba(242,242,242,1) 0%, rgba(254,254,255,0.8281687675070029) 100%, rgba(255,255,255,1) 100%);
    

}
.activestep {
    /* background: #004545c9 !important; */
    background: #D9E9E9 !important;
    color: #000 !important;
    font-weight: 500;
    /* color: #fff !important; */
}

.activestepreview {
    background: rgb(242,242,242);
    background: linear-gradient(0deg, rgba(242,242,242,1) 0%, rgba(209,224,224,1) 0%, rgba(242,242,242,1) 100%);
}


.form-container {
    width: 100%;
    min-height: 67vh;
    display:flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    vertical-align: center;
}

.form-group {
    width: 100%;
    margin-top: 1.5vh;
    margin-bottom: 1.5vh;
}

.form-group.required .control-label:after {
    content:"*";
    color:red;
  }

.form-control:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
} 

.error {
    border-color: #ef8484 !important;
    /* box-shadow: 0 0 0 0.1rem rgb(193, 100, 100) !important; */
}

.myinput {
    width: 100%;
    border-radius: 2px;
}

.mylabel {
    font-size: 1em;
    font-weight: 550;
    /* color: rgb(105, 105, 105); */
    color: #000;
    margin-bottom: 0.6vh;
}

.dependantlist {
    list-style-type: none;
}

.dependantstep {
    padding-top: 0.5vh;
    padding-bottom: 0.5vh;
    font-size: 0.8em;
}

.dependantdelete {

}

.dependantdelete:hover {
    cursor: pointer;
    color:#016e57;
}

.dependantname {
    font-weight: 500;
}

.dependantname:hover {
    cursor: pointer;
    color:#016e57;
}

.slider-step-group {
    display: flex;
    justify-content: center;
}

.sliderheader {
    color: #004545;
    font-size: 1.2em;
    margin-left: 3vh;
    font-weight: bold;
    /* margin-top: 3vh; */
    /* margin-bottom: 3vh; */
    /* text-align: center; */
}

.slider-step {
    height: 8px;
    width: 8px;
    border: 1px solid #004545;
    border-radius: 50%;
    display: inline-block;
    margin: 0.11vh;
    transition: all 0.15s ease-in;
}

.slider-step-activate {
    background-color: #004545;
}

.slider-arrows {
    background-color: #004545;
    color: #fff;
    border-radius: 50%;
    padding-top: 0.2em;
    padding-bottom: 0.4em;
    padding-left: 0.5em;
    padding-right: 0.5em;
    cursor: pointer;

}

.slider-edit {
    background-color: #F6FCFC;
    color: #004545;
    border-radius: 2px;
    font-size: 0.8em !important;
    font-style: italic;
    padding-top: 0.5rem;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}

.slider-edit:hover{
    color: #0045458a;
}

.slider-icon {

    margin-right: 0.5rem;


}

.close-edit {
    background-color: #F6FCFC;
    color: rgba(0, 0, 0, 0.566);
    cursor: pointer;

}

.close-edit:hover {
    color: rgb(104, 103, 103);

}

.close-icon {
    
}

.slider-arrows:hover {
    border: 1px solid #004545;
    color: #004545;
    background-color: #D9E9E9;
}

.dependantstepper {
    height: 2vh;
    width: 2vh;
    border-radius: 50%;
    border: 1px solid #004545;
    text-align: center;
    margin-right: 2vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-container .react-date-picker__wrapper {
    width: 100%;
    border: 0 !important;
    border-radius: 2px;
    line-height: 1.5 !important;
}

.form-container .react-date-picker__wrapper .react-date-picker__button {
    padding: 0 !important;
    margin: 0.9vh;
}

.form-container .react-date-picker__wrapper .react-date-picker__inputGroup {
    padding-left: 0.5vw;
}

.application-title {
    font-weight: 550;
}


@media (max-width: 768px) {

    .application-container {
        display: block;
        min-height: 65vh;
        width: 100%;
        background-color: #004545;
        padding-top: 5vh;
        padding-left: 2vh;
        padding-right: 2vh;
        -webkit-box-shadow: 2px 3px 19px 3px #333333; 
        box-shadow: 2px 3px 19px 3px #333333;
    }
    
    .applicationform {
        min-height: 70vh;
        width: 100%;
        background-color: #fff;
        -webkit-box-shadow: 5px 2px 13px 0px rgba(0,0,0,0.5); 
        box-shadow: 5px 2px 13px 0px rgba(0,0,0,0.5);
    }

    .steps {
        font-size: 0.8em;
    }

}