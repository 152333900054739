.buttonstyle {
    border-radius: 2px;
    width:80% !important;
    /* height: 6vh !important; */
    /* height: 30px; */
    /* margin: 0.1em 1em 0.6em 1em; */
    text-align: center;
    /* height: 8vh; */
}

.buttontext {
    color: #fff !important;
    display: inline-block;
    
}

.otherbuttontext {
    font-weight: 110;
    color: #000;
}

.textbold {
    font-weight: 500;
}

.buttoncontainer {

    /* background-color: rgba(0, 0, 0, 0.196); */
    background-color: #004241;
    /* padding: 1em; */
    width: 90%;
    color: white;
    
    /* background-color: #C5D9D9; */
    /* -webkit-box-shadow: 5px 2px 13px 0px rgba(0,0,0,0.5); 
    box-shadow: 5px 2px 13px 0px rgba(0,0,0,0.5); */
    border-radius: 3px;
    -webkit-box-shadow: 8px 16px 22px 6px rgba(0,0,0,0.41); 
    box-shadow: 8px 16px 22px 6px rgba(0,0,0,0.41);
    
    /* opacity: 0.95; */
}

         

 
 