.menu-container {

    background-color: #004241;
    -webkit-box-shadow: 1px 3px 8px -1px #000000; 
    box-shadow: 1px 3px 8px -1px #000000;
    margin-bottom: 1vh;
}

.portalnav-container {

    background-color: #004241;
    /* -webkit-box-shadow: 1px 7px 15px -3px rgba(0,0,0,0.66); 
    box-shadow: 1px 7px 15px -3px rgba(0,0,0,0.66); */
    /* margin-bottom: 1vh; */
    -webkit-box-shadow: 0px 9px 15px -2px #9E9E9E; 
    box-shadow: 0px 9px 15px -2px #9E9E9E;
}

.portalnav-width {
    width: 7vw;
}

.portalnav-active {

    background-color: rgba(255, 255, 255, 0.103);
    border-radius: 3px;
    /* color: #004241 !important; */
    width: 9vw;

}

.menu {
    color: #fff;
    margin-left: 5em;
    margin-right: 5em;
    margin-top: 1em;
    font-size: 0.8em;
    font-weight: bold;
    cursor: pointer;
}

.menu:hover {
    color: rgba(255, 255, 255, 0.571);
}

.menu-bold {
    font-weight: 500;
}

.menu-item {
    font-size: 1em;
    color: #fff;
    cursor: pointer;
    margin-left: 3vh;
    margin-right: 3vh;
}

.menu-item:hover {
    color: rgba(255, 255, 255, 0.571);
}

#home-dropdown {
    color: #fff;
    margin-left: 3vh;
    margin-right: 3vh;
    font-size: 0.9em;
    background-image: linear-gradient(to bottom, #2b79c3 0%,  #3287d7  100%);
    border-radius: 2px !important;
    padding: 1vh;
}

#home-dropdown div {
    border-radius: 2px !important;
}

#home-dropdown:hover {

    color: rgba(255, 255, 255, 0.571);

}

#howto-dropdown {
    color: #fff;
    font-size: 0.95em;
}

#howto-dropdown div {
    border-radius: 2px !important;
}

.dropdown-items{
    padding-top: 0.3vh;
    padding-bottom: 0.3vh;
    margin-top:0;
    margin-bottom:0;
}

.mynav {
    color: #fff;
}

.white {
    background-color: #fff;
}

/* .test {
    border-radius: 3px !important;
} */

.dropmenu div {
    border-radius: 3px !important;
}

/* .innertext2 {
    color: #fff;
    text-align: center;
    font-size:5em;
    padding: 1em;
    display: block;
} */

.model-title-style2 {
    font-size: 1.5em !important;
    font-weight: bold !important;
    text-align: center !important;
    color: #016e57;
}

.h-text {
    text-align: center;
}

.mini-form-container .react-date-picker__wrapper {
    width: 100%;
    border: 0 !important;
    border-radius: 2px;
    line-height: 1.5 !important;
}

.mini-form-container .react-date-picker__wrapper .react-date-picker__button {
    padding: 0 !important;
    margin: 0.9vh;
}

.mini-form-container .react-date-picker__wrapper .react-date-picker__inputGroup {
    padding-left: 0.5vw;
}


@media (max-width: 768px) {

    .portalnav-width {
        width: 70vw;
    }
    
    .portalnav-active {
    
        background-color: rgba(255, 255, 255, 0.103);
        border-radius: 3px;
        width: 70vw;
    
    }

}

